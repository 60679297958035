import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { NewsComponent } from "./news/news.component";
import { PricesComponent } from "./prices/prices.component";
import { InfoComponent } from "./info/info.component";
import { ImagesComponent } from "./images/images.component";
import { VicinityComponent } from "./vicinity/vicinity.component";
import { ContactComponent } from "./contact/contact.component";
import { FlatsComponent } from "./flats/flats.component";
import { SitenoticeComponent } from "./footer/sitenotice/sitenotice.component";
import { PrivacyComponent } from "./footer/privacy/privacy.component";
import { GeneraltacComponent } from "./footer/generaltac/generaltac.component";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "news", component: NewsComponent },
  { path: "prices", component: PricesComponent },
  { path: "info", component: InfoComponent },
  { path: "vicinity", component: VicinityComponent },
  { path: "images", component: ImagesComponent },
  { path: "contact", component: ContactComponent },
  { path: "flats", component: FlatsComponent },
  { path: "sitenotice", component: SitenoticeComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "generaltac", component: GeneraltacComponent },
  { path: "**", redirectTo: "home", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
