import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ApplicationRef } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AgmCoreModule } from "@agm/core";

import { AppComponent } from "./app.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { MenueComponent } from "./menue/menue.component";
import { HomeComponent } from "./home/home.component";
import { NewsComponent } from "./news/news.component";
import { PricesComponent } from "./prices/prices.component";
import { AppRoutingModule } from ".//app-routing.module";
import { InfoComponent } from "./info/info.component";
import { ImagesComponent } from "./images/images.component";
import { VicinityComponent } from "./vicinity/vicinity.component";
import { ContactComponent } from "./contact/contact.component";
import { FlatsComponent } from "./flats/flats.component";
import { SitenoticeComponent } from "./footer/sitenotice/sitenotice.component";
import { GeneraltacComponent } from "./footer/generaltac/generaltac.component";
import { PrivacyComponent } from "./footer/privacy/privacy.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    MenueComponent,
    HomeComponent,
    NewsComponent,
    PricesComponent,
    InfoComponent,
    ImagesComponent,
    VicinityComponent,
    ContactComponent,
    FlatsComponent,
    SitenoticeComponent,
    GeneraltacComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    TooltipModule.forRoot(),
    AppRoutingModule,
    CommonModule,
    FormsModule,
    AgmCoreModule.forRoot({ apiKey: "AIzaSyDdXfH12O0bxvpLV3NTBy6sPGni5n_r7pk" })
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {}
