import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"]
})
export class ContactComponent implements OnInit {
  lat = 47.99131;
  lng = 13.13068;
  constructor() {}

  ngOnInit() {}
}
