import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sitenotice',
  templateUrl: './sitenotice.component.html',
  styleUrls: ['./sitenotice.component.css']
})
export class SitenoticeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
